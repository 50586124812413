// Generated by Framer (2ca17d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kdQsXtSD9", "hxbXzwRnw", "kqJaQqOem", "fp8h0Du6b"];

const serializationHash = "framer-ILnG3"

const variantClassNames = {fp8h0Du6b: "framer-v-13bslsz", hxbXzwRnw: "framer-v-er2fbj", kdQsXtSD9: "framer-v-150dftc", kqJaQqOem: "framer-v-gotgz6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 2, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Bottom Left": "fp8h0Du6b", "Bottom RIght": "kqJaQqOem", "Top Left": "kdQsXtSD9", "Top Right": "hxbXzwRnw"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kdQsXtSD9"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kdQsXtSD9", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear17ld8gd = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 1980)
})

useOnVariantChange(baseVariant, {default: onAppear17ld8gd})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-150dftc", className, classNames)} data-framer-name={"Top Left"} data-highlight layoutDependency={layoutDependency} layoutId={"kdQsXtSD9"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}} {...addPropertyOverrides({fp8h0Du6b: {"data-framer-name": "Bottom Left"}, hxbXzwRnw: {"data-framer-name": "Top Right"}, kqJaQqOem: {"data-framer-name": "Bottom RIght"}}, baseVariant, gestureVariant)}><motion.div className={"framer-d9uz6g"} data-framer-name={"Stroke"} layoutDependency={layoutDependency} layoutId={"YGaYa3fVA"} style={{background: "radial-gradient(10% 15% at 4.6% 0%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24}} variants={{fp8h0Du6b: {background: "radial-gradient(17% 11% at 0% 97.7%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}, hxbXzwRnw: {background: "radial-gradient(17% 17% at 100% 3.9%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}, kqJaQqOem: {background: "radial-gradient(15% 15% at 96.89999999999999% 100%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ILnG3.framer-1hnd1zs, .framer-ILnG3 .framer-1hnd1zs { display: block; }", ".framer-ILnG3.framer-150dftc { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 256px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 263px; }", ".framer-ILnG3 .framer-d9uz6g { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ILnG3.framer-150dftc { gap: 0px; } .framer-ILnG3.framer-150dftc > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-ILnG3.framer-150dftc > :first-child { margin-top: 0px; } .framer-ILnG3.framer-150dftc > :last-child { margin-bottom: 0px; } }", ".framer-ILnG3.framer-v-er2fbj.framer-150dftc, .framer-ILnG3.framer-v-gotgz6.framer-150dftc, .framer-ILnG3.framer-v-13bslsz.framer-150dftc { aspect-ratio: 1.066 / 1; height: var(--framer-aspect-ratio-supported, 247px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 247
 * @framerIntrinsicWidth 263
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hxbXzwRnw":{"layout":["fixed","fixed"]},"kqJaQqOem":{"layout":["fixed","fixed"]},"fp8h0Du6b":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWK1VsP2t2: React.ComponentType<Props> = withCSS(Component, css, "framer-ILnG3") as typeof Component;
export default FramerWK1VsP2t2;

FramerWK1VsP2t2.displayName = "Glow Stroke/Full";

FramerWK1VsP2t2.defaultProps = {height: 247, width: 263};

addPropertyControls(FramerWK1VsP2t2, {variant: {options: ["kdQsXtSD9", "hxbXzwRnw", "kqJaQqOem", "fp8h0Du6b"], optionTitles: ["Top Left", "Top Right", "Bottom RIght", "Bottom Left"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerWK1VsP2t2, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})